import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import Button from "../ui/button";
import parse from "html-react-parser";
import { CareerOverviewRow, CareerOverviewCenteredCol, Title, Excerpt } from "./careers.style";
import { Col } from "../ui/wrapper";

const CareerOverviewRowBox = props => {
  const { id, title, excerpt, path, isOddRow, ...restProps } = props;
  const { buttonTwoStyle } = restProps;
  return (
    <CareerOverviewRow isOddRow={isOddRow}>
      <CareerOverviewCenteredCol lg={3}>
        <Title>{parse(title)}</Title>
      </CareerOverviewCenteredCol>

      <CareerOverviewCenteredCol lg={6}>
        {excerpt && <Excerpt dangerouslySetInnerHTML={{ __html: excerpt }} />}
      </CareerOverviewCenteredCol>

      <CareerOverviewCenteredCol lg={3}>
        <Button
          as={Link}
          className={"ml-md-2; mr-md-2; mt-3"}
          to={path}
          {...buttonTwoStyle}
        >
          Apply Now
        </Button>
      </CareerOverviewCenteredCol>
    </CareerOverviewRow>
  );
};

CareerOverviewRowBox.propTypes = {
  title: PropTypes.string,
  excerpt: PropTypes.string,
  path: PropTypes.string,
  buttonTwoStyle: PropTypes.object
};

CareerOverviewRowBox.defaultProps = {
  buttonTwoStyle: {
    varient: "outlined",
    padding: "0 26px"
  }
};

export { CareerOverviewRowBox };
