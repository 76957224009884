import React from 'react'
import PropTypes from 'prop-types'
import {SectionMultiTitleWrap} from './section-multi-title.style'

const SectionMultiTitle = ({title, subtitle, subtitle1, subtitle2, layout, ...props}) => {
    if(layout === 1){
        return (
            <SectionMultiTitleWrap {...props} layout={layout}>
                {subtitle && <h6>{subtitle}</h6>}
                {title && <h3 dangerouslySetInnerHTML={{ __html: title}} />}
                {subtitle1 && <h5 dangerouslySetInnerHTML={{ __html: subtitle1}} />}
                {subtitle2 && <p dangerouslySetInnerHTML={{ __html: subtitle2}} />}
            </SectionMultiTitleWrap>
        )
    }
    return (
        <SectionMultiTitleWrap {...props} layout={layout}>
            {title && <h3 dangerouslySetInnerHTML={{ __html: title}} />}
            {subtitle && <h6>{subtitle}</h6>}
            {subtitle1 && <h3 dangerouslySetInnerHTML={{ __html: subtitle1}} />}
            {subtitle2 && <h3 dangerouslySetInnerHTML={{ __html: subtitle2}} />}
        </SectionMultiTitleWrap>
    )
}

SectionMultiTitle.propTypes = {
    align: PropTypes.oneOf(['left', 'center', 'right']),
    mb: PropTypes.string
}

SectionMultiTitle.defaultProps = {
    align: 'center',
    layout: 1
}
export default SectionMultiTitle;