import React from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";
import { Container, Row, Col } from "../../../components/ui/wrapper";
import SectionTitle from "../../../components/ui/section-title";
import SectionMultiTitleWrap from "../../../components/ui/section-multi-title";
import Text from "../../../components/ui/text";
import { CareerOverviewMainContainer, SectionWrap } from "./careers-overview-area.style";
import {CareerOverviewRowBox} from "../../../components/careers";
import RecruitmentProcessArea from '../../contact-us/recruitment-process-area';

const CareersOverviewArea = props => {
  const careersQueryData = useStaticQuery(graphql`
    query CareersDataQuery {
      allCareersJson(sort: {fields: sortOrder}, filter: {is_active: {eq: true}}) {
        edges {
          node {
            id
            title
            excerpt
            fields {
              slug
            }            
          }
        }
      }
    }
  `);
  const careersData = careersQueryData.allCareersJson.edges;
  return (
    <SectionWrap>
      <Container>
        <Row>
          <Col lg={12}>
            <SectionTitle title="Let's build something extraordinary <span>together.</span>" />
          </Col>
        </Row>
      </Container>
      <RecruitmentProcessArea/>

      <CareerOverviewMainContainer>
        <Row extend={{paddingBottom: "40px"}}>
          <Col lg={12}>
            <SectionMultiTitleWrap
              subtitle="Current Opportunities"
              title="Join us to work on challenging projects"
              subtitle1="We’re <span>Hiring</span>, Get on board!"
              subtitle2="Check out our open positions and apply online"
            />
          </Col>
        </Row>
        {careersData && careersData.map((careerDataNode, careerIndexNum) => (
          <CareerOverviewRowBox
            title={careerDataNode.node.title}
            excerpt={careerDataNode.node.excerpt}
            path={`/careers/${careerDataNode.node.fields.slug}`}
            isOddRow={(careerIndexNum % 2)===0?false:true}
          />
        ))}
      </CareerOverviewMainContainer>
    </SectionWrap>
  );
};

CareersOverviewArea.propTypes = {
};

CareersOverviewArea.defaultProps = {
};

export default CareersOverviewArea;