import React from "react"
import SEO from "../components/seo"
import Layout from '../containers/layout/layout'
import Header from '../containers/layout/header/header-one'
import Footer from '../containers/layout/footer/footer-one'
import PageHeader from '../components/pageheader'
import CTAArea from '../containers/global/cta-area/section-one'
import CareersOverviewArea from "../containers/global/careers-overview-area"

const seo = {
    title: "Careers",
    description: "Let's build something extraordinary together.",
    image: "/dark-logo.png",
    pathname: "/careers",
  };

const CareersPage = ({ pageContext, location }) => (
    <Layout location={location}>
    <SEO
      title={seo.title}
      description={seo.description}
      image={`${seo.image}`}
      pathname={seo.pathname}
    />
        <Header/>
        <PageHeader 
            pageContext={pageContext} 
            location={location}
            title="Careers @ Synergenie"
        />
        <main className="site-wrapper-reveal">
            <CareersOverviewArea/>
            <CTAArea 
                headingText="Haven't found the position you're interested in? <br/>Do you have the <span>talent</span>, <span>drive</span> and a great <span>attitude</span>?"
                letsTalkLink={{
                    label: "Let's talk",
                    path: "mailto: careers@synergenie.com?subject=Job Application"
                }}
            />
        </main>
        <Footer/>
    </Layout>
  )
   
  export default CareersPage